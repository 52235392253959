.react-utc-datepicker_input {
    padding: 6px;
}
.react-utc-datepicker_button {
    padding: 7px;
}
.react-utc-datepicker_button.before {
    margin-right: 2px;
}
.react-utc-datepicker_button.after {
    margin-left: 2px;
}
.react-utc-datepicker_datepicker {
    position: absolute;
    top: auto;
    left: auto;
    z-index: 1000;
}
.react-utc-datepicker_datepicker .react-utc-datepicker_calendar-icon {
    cursor: pointer;
}
.react-utc-datepicker_datepicker .react-utc-datepicker_calendar-popup {
    width: 245px;
    opacity: 0.9;
    border-left: 1px solid #888;
}
.react-utc-datepicker_datepicker .react-utc-datepicker_calendar-popup.react-utc-datepicker_above {
    margin-top: -293px;
}
.react-utc-datepicker_datepicker .react-utc-datepicker_calendar-popup.react-utc-datepicker_below {
    margin-top: 5px;
}
.react-utc-datepicker_datepicker .react-utc-datepicker_calendar-popup .react-utc-datepicker_calendar-controls {
    box-sizing: border-box;
    background: #ccc;
    width: 100%;
    padding: 5px 0;
    text-align: center;
    color: #000;
    border-top: 1px solid #888;
    border-right: 1px solid #888;
    display: flex;
    justify-content: space-between;
}
.react-utc-datepicker_datepicker .react-utc-datepicker_calendar-popup .react-utc-datepicker_calendar-controls .react-utc-datepicker_prev,
.react-utc-datepicker_datepicker .react-utc-datepicker_calendar-popup .react-utc-datepicker_calendar-controls .react-utc-datepicker_next,
.react-utc-datepicker_datepicker .react-utc-datepicker_calendar-popup .react-utc-datepicker_calendar-controls .react-utc-datepicker_today {
    cursor: pointer;
    padding: 0 7px;
}
.react-utc-datepicker_datepicker .react-utc-datepicker_calendar-popup .react-utc-datepicker_day-names {
    background: #555;
    color: #fff;
    display: flex;
}
.react-utc-datepicker_datepicker .react-utc-datepicker_calendar-popup .react-utc-datepicker_day-names .react-utc-datepicker_name {
    width: 35px;
    height: 15px;
    padding: 1px;
    text-align: center;
}
.react-utc-datepicker_datepicker .react-utc-datepicker_calendar-popup .react-utc-datepicker_calendar {
    background: #fff;
    width: 245px;
}
.react-utc-datepicker_datepicker .react-utc-datepicker_calendar-popup .react-utc-datepicker_calendar .react-utc-datepicker_day {
    box-sizing: border-box;
    width: 35px;
    height: 35px;
    display: inline-block;
    float: left;
    padding: 2px;
    border-right: 1px solid #888;
    border-bottom: 1px solid #888;
    color: #000;
    background: #fff;
}
.react-utc-datepicker_datepicker .react-utc-datepicker_calendar-popup .react-utc-datepicker_calendar .react-utc-datepicker_day:hover {
    background: #ccc;
    color: #000;
    cursor: pointer;
}
.react-utc-datepicker_datepicker .react-utc-datepicker_calendar-popup .react-utc-datepicker_calendar .react-utc-datepicker_day:last-child {
    border-right: 1px solid #888;
}
.react-utc-datepicker_datepicker .react-utc-datepicker_calendar-popup .react-utc-datepicker_calendar .react-utc-datepicker_selected {
    background: #42a5f5;
    color: #fff;
}
.react-utc-datepicker_datepicker .react-utc-datepicker_calendar-popup .react-utc-datepicker_calendar .react-utc-datepicker_disabled {
    background: #ddd;
    color: #555;
}
.react-utc-datepicker_clear {
    clear: both;
}
